/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import Cart from '../components/Season/Cart';
import Checkout from '../components/Season/Checkout';
import CountDown from '../components/Season/CountDown';
import Loading from '../components/Loading';
import React, { useEffect, useState } from 'react';
import {
  fetchDrawdownList,
  fetchSeasonInfo,
  getPlayerTicketList,
} from '../actions/season';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  removeDrawdonwSection,
  resetSections,
  resetTickets,
  setSelectedSection,
} from '../slices/seasonSlice';
import SecondaryButton from '../components/Button/SecondaryButton';
import BuyTicket from '../components/Modal/BuyTicket';
import LayoutUser from '../components/Layout/LayoutUser';
import HowToPlay from '../components/Season/HowToPlay';
import WinAnnouncement from '../components/Season/WinAnnoucement';
import { useIsLoggedIn } from '../hooks/useAuth';
import moment from 'moment-timezone';
import SeasonTitle from '../components/Season/SeasonTitle';
import CatLogo from '../assets/imgs/cat-logo.png';
import { enqueueSnackbar } from 'notistack';
import { CURRENT_TIME } from '../utils/date';
import VideoAnnoucement from '../components/Season/VideoAnnouncement';
import WinnerBreakdown from '../components/Season/WinnerBreakdown';
import { getTotalTickets } from '../utils/math';

const SeasonDetailPage = () => {
  const {
    isLoading,
    selectedSeasonInfo,
    selectedDrawdownInfo,
    selectedAnnouncement,
    availableDrawdownList,
    sections,
    selectedSection,
    selectedTickets,
    tempGeneratedTickets,
    seasonFilter,
  } = useSelector((state) => state.season);
  const [showBuyTicket, setShowBuyTicket] = useState(false);
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    dispatch(fetchSeasonInfo({ id }));
    dispatch(fetchDrawdownList({ lottery_season_id: id }));
    return () => {
      dispatch(resetTickets());
      dispatch(resetSections());
    };
  }, [dispatch, id]);

  useEffect(() => {
    const seasonSection = searchParams.get('section');
    if (seasonSection) {
      dispatch(setSelectedSection(seasonSection));
    } else {
      dispatch(setSelectedSection('drawdown'));
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedSeasonInfo && isLoggedIn) {
      if (selectedSection === 'drawdown' || selectedSection === 'winner') {
        if (availableDrawdownList && availableDrawdownList.length) {
          dispatch(
            getPlayerTicketList({
              lottery_season_id: selectedSeasonInfo.lottery_season.id,
              drawdown_id_list: availableDrawdownList.map((d) => d.drawdown.id),
            })
          );
        }

        if (
          selectedSeasonInfo.active_drawdown.draw_date &&
          moment(CURRENT_TIME).isAfter(
            selectedSeasonInfo.active_drawdown.draw_date
          )
        ) {
          dispatch(removeDrawdonwSection());
          setSearchParams({ section: 'winner' }, { replace: true });
        }
      }
    }
  }, [isLoggedIn, selectedSeasonInfo, selectedSection, availableDrawdownList]);

  return (
    <LayoutUser
      showDetailNav={true}
      backTo={`/play?season_filter=${seasonFilter}`}
    >
      {isLoading ? <Loading /> : null}
      {showBuyTicket ? <BuyTicket closeModal={setShowBuyTicket} /> : null}
      {selectedSeasonInfo ? (
        <main className="relative bg-yellow">
          <div
            className={`w-full h-[185px] sm:h-[40vh] relative ${
              selectedSeasonInfo.banner && selectedSeasonInfo.banner.image
                ? 'bg-transparent'
                : 'bg-grey-banner'
            }`}
          >
            {selectedSeasonInfo.banner && selectedSeasonInfo.banner.image ? (
              <img
                src={selectedSeasonInfo.banner.image}
                alt={`${selectedSeasonInfo.lottery_season.name} banner`}
                className="w-full h-full object-cover"
              />
            ) : null}
            <SeasonTitle isBanner={true} />
          </div>
          <div className="bg-white sm:w-[80%] sm:m-auto sm:border sm:border-black-100 sm:mt-[-40px]">
            <div className="flex flex-row sm:justify-between overflow-auto max-w-none w-full bg-white relative z-10">
              {sections.map((section) => (
                <button
                  key={section.query}
                  className={`px-[16px] min-w-max sm:w-full py-[8px] font-poppins-semibold border-l border-l-black-100 last:border-r last:border-r-black-100 border-y border-y-black-100 ${
                    selectedSection === section.query
                      ? 'bg-black-100 text-white'
                      : 'text-black-100 bg-white'
                  } ${sections.length < 3 && 'w-1/2'}`}
                  onClick={() => setSearchParams({ section: section.query })}
                >
                  {section.name}
                </button>
              ))}
            </div>
            {selectedSection === 'drawdown' ? (
              <>
                <SeasonTitle />
                <div className="sm:flex sm:flex-row sm:justify-between w-full sm:py-[32px]">
                  <div className="flex flex-col gap-[16px] sm:w-[48%]">
                    {selectedSeasonInfo.active_drawdown &&
                    selectedSeasonInfo.active_drawdown.purchase_freeze_time ? (
                      <div className="px-[16px]">
                        <h4 className="text-lg font-poppins-semibold text-blue">
                          Ticket purchase for{' '}
                          {selectedSeasonInfo.active_drawdown.drawdown.name}
                          {new Date(selectedSeasonInfo.startDate) > CURRENT_TIME
                            ? ' starts'
                            : ' closes'}{' '}
                          in:
                        </h4>
                        <div className="w-[80%] mt-[10px] sm:w-[100%]">
                          <CountDown
                            startDate={selectedSeasonInfo.startDate}
                            drawDate={
                              selectedSeasonInfo.active_drawdown
                                .purchase_freeze_time
                            }
                          />
                        </div>
                      </div>
                    ) : null}

                    {selectedAnnouncement &&
                    selectedDrawdownInfo &&
                    selectedSeasonInfo.active_drawdown._id ===
                      selectedDrawdownInfo._id ? (
                      <div className="sm:px-[16px] sm:pt-0">
                        <VideoAnnoucement isSeasonDetail={true} />
                      </div>
                    ) : null}

                    <WinnerBreakdown />

                    {selectedSeasonInfo.sales_pitch &&
                    selectedSeasonInfo.sales_pitch.length ? (
                      <article className="bg-yellow sm:ml-[16px] px-[16px] pt-[10px] border border-black-100 sm:shadow-thick font-poppins-semibold whitespace-pre-wrap">
                        {selectedSeasonInfo.sales_pitch.map((pitch) => (
                          <p className="mb-[10px]" key={pitch._id}>
                            {pitch.description}
                          </p>
                        ))}
                      </article>
                    ) : null}
                  </div>
                  <div className="hidden sm:flex max-h-[60vh] flex-col border border-black-100 py-[24px] px-[16px] w-[45%] mr-[24px] shadow-thick bg-blue">
                    <div className="flex flex-row justify-between font-poppins-semibold pb-[10px]">
                      <h3 className="text-lg">Get Tickets</h3>
                      <p className="text-sm">
                        {getTotalTickets(tempGeneratedTickets)} tickets
                      </p>
                    </div>
                    <div className="max-h-[50vh] overflow-auto flex flex-col gap-[20px]">
                      {selectedTickets.length && tempGeneratedTickets.length ? (
                        tempGeneratedTickets.map((ticket, idx) => (
                          <React.Fragment key={`ticket ${idx}`}>
                            {ticket.ticket_list.map((dTicket, dIdx) => (
                              <div
                                key={`d-${idx}-${dIdx}`}
                                className="w-[98%] flex flex-col px-[12px] pt-[8px] pb-[20px] mb-[4px] border border-black-100 shadow-thick rounded-lg bg-light-yellow"
                              >
                                <p className="text-sm font-poppins-semibold text-right mb-[4px]">
                                  {ticket.drawdown.name} #{dIdx + 1}
                                </p>
                                <div className="flex flex-row gap-[8px] justify-center">
                                  {dTicket.map((num, id) => (
                                    <span
                                      key={`t-${idx} ${id}`}
                                      className="text-sm w-[26px] h-[26px] bg-black-100 rounded-full flex items-center justify-center font-poppins-bold text-white"
                                    >
                                      {num}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </React.Fragment>
                        ))
                      ) : (
                        <div className="flex flex-col mt-[24px] justify-center items-center text-center">
                          <img
                            src={CatLogo}
                            className="w-[125px h-[168px]"
                            alt="cat-logo"
                          />
                          <p className="text-[10px] mt-[8px]">
                            Add and choose your tickets.
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="mt-auto pt-[8px]">
                      <SecondaryButton
                        title={
                          tempGeneratedTickets && tempGeneratedTickets.length
                            ? 'Edit Tickets'
                            : 'Add New Tickets'
                        }
                        onClick={() => {
                          if (
                            !selectedSeasonInfo.active_drawdown
                              .purchase_freeze_time ||
                            (selectedSeasonInfo.active_drawdown
                              .purchase_freeze_time &&
                              moment(CURRENT_TIME).isBefore(
                                selectedSeasonInfo.active_drawdown
                                  .purchase_freeze_time
                              ))
                          ) {
                            setShowBuyTicket(true);
                          } else if (
                            moment(CURRENT_TIME).isBefore(
                              selectedSeasonInfo.startDate
                            )
                          ) {
                            enqueueSnackbar('The season has not started yet', {
                              variant: 'warning',
                            });
                          } else {
                            enqueueSnackbar(
                              'You can no longer purchase tickets for this drawdown',
                              { variant: 'warning' }
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : selectedSection === 'tutorial' ? (
              <HowToPlay />
            ) : selectedSection === 'winner' ? (
              <WinAnnouncement />
            ) : null}
          </div>
        </main>
      ) : null}
      {selectedSeasonInfo &&
      moment(CURRENT_TIME).isAfter(selectedSeasonInfo.startDate) &&
      ((selectedSection === 'drawdown' &&
        (!selectedSeasonInfo.active_drawdown.draw_date ||
          (selectedSeasonInfo.active_drawdown.draw_date &&
            moment(CURRENT_TIME).isBefore(
              selectedSeasonInfo.active_drawdown.draw_date
            )))) ||
        (selectedSection === 'winner' &&
          selectedDrawdownInfo &&
          (selectedDrawdownInfo.status === 'Active' ||
            availableDrawdownList.findIndex(
              (d) => d._id === selectedDrawdownInfo._id
            ) >= 0) &&
          (!selectedDrawdownInfo.purchase_freeze_time ||
            moment(CURRENT_TIME).isBefore(
              selectedDrawdownInfo.purchase_freeze_time
            )))) ? (
        <>
          <Cart openBuyModal={setShowBuyTicket} />
          <Checkout />
        </>
      ) : null}
    </LayoutUser>
  );
};

export default SeasonDetailPage;
